/* Responsive design for small screens */
@media (max-width: 768px) {
  body {
    display: flex;
    justify-content: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the container content */
    width: 100%; /* Full width */
    max-width: 100%; /* Ensure the container doesn't exceed screen width */
    margin: 0 auto; /* Center the container horizontally */
    padding: 10px;
  }

  .content-wrapper {
    flex-direction: column; /* Stack sidebar and content vertically */
    width: 100%; /* Ensure the content wrapper takes full width */
    align-items: center;
  }

  .sidebar {
    width: 100%; /* Sidebar takes full width on mobile */
    position: relative; /* Remove sticky position for mobile */
    margin-bottom: 20px;
  }

  .main-content {
    width: 100%; /* Main content takes full width on mobile */
  }

  .header h1 {
    font-size: 1.8rem; /* Smaller font size for mobile header */
  }

  .content-section h2 {
    font-size: 1.5rem; /* Smaller font size for section titles */
  }

  .basic-details p, .content-section p {
    font-size: 0.9rem; /* Reduce font size slightly for mobile */
  }

  .profile-picture {
    width: 100px;
    height: 100px; /* Smaller profile picture for mobile */
  }
}

/* Original Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #11461d, #40bc5d, #9bd49e);
  background-size: 200% 200%;
  animation: greenWaveAnimation 10s ease infinite;
  color: #333;
}

@keyframes greenWaveAnimation {
  0% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  opacity: 0;
  transform: scale(0.95);
  animation: fadeInZoom 0.6s ease forwards;
  animation-delay: 0.1s;
}

.header {
  text-align: center;
  padding: 20px;
  background-color: #2d6a4f;
  color: white;
  border-radius: 10px 10px 0 0;
  opacity: 0;
  transform: scale(0.95);
  animation: fadeInZoom 0.6s ease forwards;
  animation-delay: 0.2s;
}

.header h1 {
  margin: 0;
  font-size: 2.5rem;
}

.content-wrapper {
  display: flex;
  flex-grow: 1;
  padding: 20px;
  gap: 20px;
}

.sidebar {
  flex: 1;
  background-color: #43a047;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 20px;
  max-height: calc(100vh - 40px);
}

.profile-picture-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.basic-details {
  text-align: center;
  color: white;
}

.basic-details h2 {
  font-size: 1.8rem;
}

.basic-details p {
  font-size: 1rem;
  margin: 10px 0;
}

.show-name h2 {
  animation: fadeIn 0.5s ease-in-out;
}

.main-content {
  flex: 3;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: scale(0.95);
  animation: fadeInZoom 0.6s ease forwards;
  animation-delay: 0.3s;
}

.content-section {
  margin-bottom: 40px;
}

.content-section h2 {
  font-size: 1.8rem;
  color: #2d6a4f;
}

.content-section p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4d4d4d;
}

.content-container {
  background-color: #e0f2f1;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #2d6a4f;
  color: white;
  border-radius: 0 0 10px 10px;
}

/* Responsive design for larger screens */
@media (min-width: 1100px) {
  .container {
    margin-left: 150px;
    margin-right: 150px;
  }
}
